<template>
  <div class="cropper">
    <Cropper
      :src="config.image"
      :stencil-props="{
        aspectRatio: config.aspectRatio,
        maxHeight: 4,
      }"
      ref="cropper"
    />
    <button
      type="button"
      class="button is-success"
      @click="updateFormCroppedImage"
      :class="{ 'is-loading': loading }"
    >
      {{ translations.common.crop }}
    </button>
  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper';
import store from '@/store';

export default {
  name: 'DatePicker',
  props: ['config'],
  components: { Cropper },
  data() {
    return { loading: false, translations: store.getters.getTranslations };
  },
  created() {
    console.log();
  },
  methods: {
    updateFormCroppedImage() {
      this.loading = true;
      const { canvas } = this.$refs.cropper.getResult();
      if (canvas) {
        canvas.toBlob((blob) => {
          this.$emit('input', blob);
          if (this.config.onSelect) {
            this.loading = false;
            this.config.onSelect(blob);
          }
        });
      }
    },
  },
};
</script>
<style lang="scss">
.cropper {
  background: $black;
  .button {
    width: 100%;
    border-radius: 0 !important;
  }
  .vue-advanced-cropper {
    max-height: calc(100vh - 20rem);
  }
}
</style>
